.printdiv {
    width: 350px;
    max-width: 350px;
    font-family: 'Times New Roman';
    /* margin: auto; */
}

.printdiv table {
    width: inherit;
    max-width: inherit;
}

.printdiv tr:not(tr:last-child), thead tr {
    border-bottom: 3px solid black;
    border-collapse: collapse;
}

td.description,
th.description {
    width: 140px;
    max-width: 140px;
    font-weight: 900;
    color: black;
    text-align: center;
}

td.price,
th.price,
td.quantity,
th.quantity {
    width: 105px;
    max-width: 105px;
    word-break: break-all;
    font-weight: 900;
    color: black;
    text-align: center;
}

.centered {
    text-align: center;
    align-content: center;
    font-weight: 800;
    color: black;
}

.printdiv img {
    max-width: inherit;
    width: inherit;
}

.printdiv .list-unstyled li {
    color: black;
    font-weight: 700;
}
.printdiv .list-unstyled li {
    display: flex;
    justify-content: space-between;
}

.printdiv .list-unstyled, .printdiv table {
    margin: 2rem 0rem;
}
#btnPrint {
    margin: 20px;
    font-size: 1.1rem;
    background-color: #DA251C;
    color: #fff;
    border: 1px solid #DA251C;
    padding: 5px 15px;
    cursor: pointer;
}

    .printdiv td.price, .printdiv td.quantity, .printdiv td.description, .printdiv th {
        font-size: 1.1rem;
    }
    .printdiv .list-unstyled li, .printdiv .centered{
        font-size: 1.1rem;
    }

    
.printdiv img {
    max-width: 150px;
    width: 150px;
}
@media print {
    
    .hidden-print,
    .hidden-print *, #btnPrint {
        display: none !important;
    }

    
}
@page {
    size: auto;
    margin: 0  15px;
    /* margin-right: 2px; */
}